import styled from "styled-components";
import Page from "../Layout/Page";
import PageContent from "../Layout/PageContent";
import PageTitle from "../Layout/PageTitle";
import Section from "../Layout/Section";
import fluid from "../utils/fluid";

import SPEAKERSTEAMLABIMAGE from "../assets/sectionImage/teamlab.png";

const SpeakerContainer = styled.div`
  font-weight: 600;
`;

const SpeakerSchedule = styled.div`
  font-size: ${fluid(2, 4)};
  line-height: ${fluid(2.8, 5.6)};
  font-weight: 400;
  margin: ${fluid(1.2, 2.4)} 0;
  text-transform: uppercase;
`;

export default function SpeakersPage(props) {
  const { title, handlePageEnter, handleSectionEnter } = props;

  return (
    <Page title={title} handlePageEnter={handlePageEnter}>
      <PageTitle>
        <SpeakerContainer>
          <SpeakerSchedule>
            Stay Tune For
            <br />
            Our Next Speaker
          </SpeakerSchedule>
        </SpeakerContainer>
      </PageTitle>
      <PageContent>
        <Section
          id="speakers"
          page={title}
          title={"teamlab"}
          handleSectionEnter={handleSectionEnter}
        >
          <SpeakerContainer>
            {/* <SpeakerSchedule>
              Stay Tune For
              <br />
              Our Next Speaker
            </SpeakerSchedule> */}
          </SpeakerContainer>
        </Section>
      </PageContent>
    </Page>
  );
}
