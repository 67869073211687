import HEROIMAGE from "../../assets/sectionImage/hero.png";
import useInView from "react-cool-inview";
import styled from "styled-components";

import { device } from "../../utils/size";
import fluid from "../../utils/fluid";

const Header = styled.header`
  min-height: 100vh;
  margin-bottom: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
`;

const PosterContainer = styled.div`
  width: 30%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;

  @media ${device.tablet} {
    width: 100%;
    max-width: ${fluid(27.5, 55)};
    min-width: auto;
  }
`;

const Poster = styled.img`
  width: 100%;
  pointer-events: none;
`;

const Scroll = styled.div`
  visibility: hidden;
  position: fixed;
  bottom: 5rem;
  height: 3rem;

  & a {
    color: white;
    font-size: 1.4rem;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
  }

  & span {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: downward 1.5s infinite;
    animation: downward 1.5s infinite;
    box-sizing: border-box;
  }

  @keyframes downward {
    0% {
      transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: rotate(-45deg) translate(-20px, 20px);
      opacity: 0;
    }
  }

  @media ${device.tablet} {
    visibility: visible;
  }
`;

export default function Main(props) {
  const { title, page, handlePageEnter } = props;
  const { ref } = useInView({
    threshold: 0,
    onEnter: () => handlePageEnter(title),
  });
  return (
    <Header id="hero" ref={ref}>
      <PosterContainer>
        <Poster
          src={HEROIMAGE}
          alt="Creative Technology and New Media Art Master Class"
        />
      </PosterContainer>
      <Scroll hidden={page !== "hero"}>
        <a href="#masterclass">
          Scroll
          <span />
        </a>
      </Scroll>
    </Header>
  );
}
