import { scaleLinear } from "d3-scale";
import canvasBorderEnvironment from "./canvasBorderEnvironment";
import baseEnvironment from "./baseEnvironment";

import Particle from "./particle";
import particleconfig from "./config";

import Vector from "../utils/Vector";
import whiteAlpha from "../utils/whiteAlpha";
import gradient from "../utils/gradient";

export let context;

export default function createParticleEffect(canvas) {
  const initShape = [...Array(250).keys()].map(() => ({
    x: Math.random(),
    y: Math.random(),
  }));
  let shape = initShape;
  function setShape(newShape) {
    if (newShape && newShape.length) {
      shape = newShape;
    } else {
      shape = initShape;
    }

    setParticleBase();
  }
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  const gradientScale = scaleLinear().domain([0, canvas.width]).range([0, 1]);

  let radius = 0.3 * canvas.width;
  function setRadius(newRadius) {
    if (newRadius) {
      radius = newRadius || 0.3 * canvas.width;
    }

    setParticleBase();
  }
  const border = canvasBorderEnvironment(canvas);
  const base = baseEnvironment(canvas);
  function resize(newRadius) {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    gradientScale.domain([0, canvas.width]);
    setRadius(newRadius);
  }

  context = canvas.getContext("2d");

  let particles = [];
  function init() {
    particles = [];
    shape.forEach(({ x: baseX, y: baseY }) => {
      const size = Math.random() * particleconfig.size + 1;
      const x = Math.random() * (canvas.width - size * 4) + size * 2;
      const y = Math.random() * (canvas.height - size * 4) + size * 2;
      const location = new Vector(x, y);

      const baseXRand =
        baseX * radius -
        radius +
        10 +
        (Math.random() * particleconfig.radiusNoise -
          particleconfig.radiusNoise / 2);
      const baseYRand =
        baseY * radius -
        radius +
        Math.random() * particleconfig.radiusNoise -
        particleconfig.radiusNoise / 2;
      const baselocation = new Vector(baseXRand, baseYRand);

      const translate = new Vector(
        (canvas.width - radius) / 2,
        (canvas.height - radius) / 2
      );

      const color = whiteAlpha(1);
      const directionX =
        Math.random() * particleconfig.speed - particleconfig.speed / 2;
      const directionY =
        Math.random() * particleconfig.speed - particleconfig.speed / 2;
      const velocity = new Vector(directionX, directionY);
      const particle = new Particle(
        location,
        baselocation,
        translate,
        new Vector(0, 0),
        size,
        color,
        gradientScale
      );
      particle.addEnvironments(border);
      // particle.addEnvironments(base);
      particles.push(particle);
    });
  }

  const CONNECTDISTANCE =
    Math.sqrt(canvas.width * canvas.height) / particleconfig.connect;
  const opacityScale = scaleLinear()
    .domain([0, CONNECTDISTANCE])
    .range([0, 0.25]);

  function connect() {
    particles.forEach((particleA) => {
      particles.forEach((particleB) => {
        const distance = Vector.subtract(
          particleA.location,
          particleB.location
        ).length();

        if (distance < CONNECTDISTANCE) {
          const opacity = opacityScale(distance);
          context.strokeStyle = particleA.color;
          context.globalAlpha = opacity;
          context.lineWidth = 1;
          context.beginPath();
          context.moveTo(particleA.location.x, particleA.location.y);
          context.lineTo(particleB.location.x, particleB.location.y);
          context.stroke();
        }
      });
    });
  }

  function animate() {
    requestAnimationFrame(animate);
    context.clearRect(0, 0, canvas.width, canvas.height);

    particles.forEach((particle) => {
      particle.update(canvas);
      if (particle.lifespan < 0) {
        const x =
          Math.random() * (canvas.width - particle.size * 4) +
          particle.size * 2;
        const y =
          Math.random() * (canvas.height - particle.size * 4) +
          particle.size * 2;
        const location = new Vector(x, y);
        particle.location = location;
        particle.atBase = false;
        particle.lifespan = particleconfig.lifespan;
      }
    });
    connect();
  }

  function setParticleBase() {
    particles.forEach((particle, i) => {
      const { x, y } = shape[i];

      const baseX =
        x * radius +
        (Math.random() * particleconfig.radiusNoise -
          particleconfig.radiusNoise / 2);
      const baseY =
        y * radius +
        (Math.random() * particleconfig.radiusNoise -
          particleconfig.radiusNoise / 2);
      const base = new Vector(baseX, baseY);

      particle.base = base;
    });
  }

  function centerTranslate() {
    particles.forEach((particle) => {
      particle.translate = new Vector(
        -radius / 2 + canvas.width / 2,
        -radius / 2 + canvas.height / 2
      );
    });
  }

  function setTranslate(x, y) {
    particles.forEach((particle) => {
      particle.translate = new Vector(x, y);
    });
  }

  function addBaseEnvironment() {
    particles.forEach((particle) => {
      if (!particle.environments.includes(base)) {
        particle.addEnvironments(base);
      }
    });
  }

  function removeBaseEnvironment() {
    particles.forEach((particle) => {
      particle.removeEnvironments(base);
      particle.velocity = new Vector(
        Math.random() * particleconfig.speed - particleconfig.speed / 2,
        Math.random() * particleconfig.speed - particleconfig.speed / 2
      );
    });
  }

  return {
    resize,
    setRadius,
    init,
    animate,
    setShape,
    setTranslate,
    centerTranslate,
    removeBaseEnvironment,
    addBaseEnvironment,
  };
}
