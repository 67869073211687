import Vector from "../utils/Vector";

import particleconfig from "./config";

export default function baseEnvironment(canvas) {
  return {
    update: function (particle) {
      const direction = Vector.subtract(
        particle.location,
        Vector.add(particle.base, particle.translate)
      );
      const distance = direction.length();

      direction.normalize();
      direction.multiply(-particleconfig.speed);

      if (Math.abs(distance) > 10) {
        if (particle.atBase) direction.multiply(0.2);
        particle.velocity = direction;
      } else if (!particle.atBase) {
        particle.atBase = true;
      }
    },
  };
}
