import useInView from "react-cool-inview";
import styled from "styled-components";
import { device } from "../utils/size";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  position: relative;
  z-index: ${({ frontOfParticle }) => (frontOfParticle ? 5 : 3)};
  justify-content: space-between;

  @media ${device.tablet} {
    flex-flow: column nowrap;
  }
`;

export default function Page(props) {
  const {
    id,
    title,
    handlePageEnter,
    children,
    frontOfParticle = false,
  } = props;
  const { ref } = useInView({
    threshold: 0,
    onEnter: () => handlePageEnter(title),
  });

  return (
    <Container id={id} ref={ref} frontOfParticle={frontOfParticle}>
      {children}
    </Container>
  );
}
