import ParticleEffect from "./components/ParticleEffect";
import "./App.css";
import styled from "styled-components";
import NavigationBar from "./components/NavigationBar";
import Hero from "./components/Hero";
import MasterClassPage from "./components/MasterClassPage";
// import SpeakersPage from "./components/SpeakersPage";
import SpeakersNext from "./components/SpeakersNext";
import PartnerPage from "./components/PartnerPage";
import AboutPage from "./components/AboutPage";
import { useState } from "react";
import { HamburgerMenu } from "./components/Hamburger";
import { isTablet } from "./utils/size";
import NewsPage from "./components/NewsPage";
import PreviousClasses from "./components/PreviousClasses";
import { newsOne, newsTwo } from "./components/NewsPage/news";

const AppContainer = styled.div`
  background: black;

  position: relative;
  min-height: 100vh;
`;

const StyledParticleEffect = styled(ParticleEffect)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  pointer-events: none;
`;

function App() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const [page, setPage] = useState("hero");
  function handlePageEnter(title) {
    console.log("--- set page to : " + title + " ---");
    setPage(title);
  }

  const [section, setSection] = useState("");
  function handleSectionEnter(title) {
    console.log("--- set section to : " + title + " ---");
    setSection(title);
  }

  return (
    <AppContainer id={"app"}>
      <StyledParticleEffect page={page} section={section} />
      <NavigationBar
        page={page}
        hamburgerOpen={hamburgerOpen}
        setHamburgerOpen={setHamburgerOpen}
      />
      {isTablet() && (
        <HamburgerMenu
          hamburgerOpen={hamburgerOpen}
          setHamburgerOpen={setHamburgerOpen}
          page={page}
        />
      )}
      <Hero title={"hero"} page={page} handlePageEnter={handlePageEnter} />
      <MasterClassPage
        title={"masterclass"}
        handlePageEnter={handlePageEnter}
        handleSectionEnter={handleSectionEnter}
      />
      {/* <SpeakersPage
        title={"speakers1"}
        handlePageEnter={handlePageEnter}
        handleSectionEnter={handleSectionEnter}
      /> */}
      <SpeakersNext
        title={"speakersnext"}
        handlePageEnter={handlePageEnter}
        handleSectionEnter={handleSectionEnter}
      />
      <NewsPage
        title={"news"}
        news={newsOne}
        handlePageEnter={handlePageEnter}
        handleSectionEnter={handleSectionEnter}
      />
      <NewsPage
        title={"newsTwo"}
        news={newsTwo}
        handlePageEnter={handlePageEnter}
        handleSectionEnter={handleSectionEnter}
      />
      <PreviousClasses
        title={"previousclasses"}
        url={"https://www.youtube.com/watch?v=vfLAoQLpPss"}
        speakerTitle={
          <>
            Speaker #6 <b>Kimchi and Chips</b>
          </>
        }
        handlePageEnter={handlePageEnter}
        handleSectionEnter={handleSectionEnter}
      />
      <PreviousClasses
        title={"previousclasses"}
        url={"https://www.youtube.com/watch?v=DT1N4KD9E2A"}
        speakerTitle={
          <>
            Speaker #5 <b>panGenerator</b>
          </>
        }
        handlePageEnter={handlePageEnter}
        handleSectionEnter={handleSectionEnter}
      />
      <PreviousClasses
        title={"previousclasses"}
        url={"https://www.youtube.com/watch?v=Ld5hDbxhUV4"}
        speakerTitle={
          <>
            Speaker #4 <b>ART+COM</b>
          </>
        }
        handlePageEnter={handlePageEnter}
        handleSectionEnter={handleSectionEnter}
      />
      <PreviousClasses
        title={"previousclasses"}
        url={"https://www.youtube.com/watch?v=sKjpfO3208E"}
        speakerTitle={
          <>
            Speaker #3 <b>onformative</b>
          </>
        }
        handlePageEnter={handlePageEnter}
        handleSectionEnter={handleSectionEnter}
      />
      <PreviousClasses
        title={"previousclassesfuturedeluxe"}
        url={"https://www.youtube.com/watch?v=QBjAYCDtZFc"}
        speakerTitle={
          <>
            Speaker #2 <b>FutureDeluxe</b>
          </>
        }
        handlePageEnter={handlePageEnter}
        handleSectionEnter={handleSectionEnter}
      />
      <PreviousClasses
        title={"previousclassesteamlab"}
        url={"https://www.youtube.com/watch?v=1TvJkgLG0oU"}
        speakerTitle={
          <>
            Speaker #1 <b>teamLab</b>
          </>
        }
        handlePageEnter={handlePageEnter}
        handleSectionEnter={handleSectionEnter}
      />
      <PartnerPage
        title={"partners"}
        handlePageEnter={handlePageEnter}
        handleSectionEnter={handleSectionEnter}
      />
      <AboutPage
        title={"info"}
        handlePageEnter={handlePageEnter}
        handleSectionEnter={handleSectionEnter}
      />
    </AppContainer>
  );
}

export default App;
