import styled from "styled-components";
import { device } from "../utils/size";

export default styled.div`
  min-height: 100vh;
  flex-basis: 65%;
  margin: 50vh 0;

  @media ${device.tablet} {
    margin: 0;
  }
`;
