import styled from "styled-components";
import fluid from "../utils/fluid";

const ActionButton = styled.a`
  display: inline-flex;
  /* width: ${fluid(15, 30)}; */
  height: ${fluid(2.9, 5.8)};
  ${(props) =>
    !props.disabled
      ? `box-shadow: 0 ${fluid(0.5, 1)} ${fluid(
          1.5,
          3
        )} rgba(255, 255, 255, 0.4);`
      : ""}

  svg {
    display: block;
  }
`;

export default ActionButton;
