import { Spin as HamburgerSpin } from "hamburger-react";
import styled from "styled-components";
import Links from "../utils/Links";

export function HamburgerIcon(props) {
  const { hamburgerOpen, setHamburgerOpen } = props;

  return (
    <HamburgerSpin
      size={28}
      distance={"sm"}
      color={"white"}
      toggled={hamburgerOpen}
      toggle={setHamburgerOpen}
    />
  );
}

const HamburgerLinks = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;

  width: 100%;
  height: 100%;

  background: black;

  pointer-events: ${({ open }) => (open ? "all" : "none")};
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 15vh;
  padding-left: 5vw;
`;

const HamburgerLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;

  & + & {
    margin-top: 3.8rem;
  }
`;

export function HamburgerMenu(props) {
  const { page, hamburgerOpen, setHamburgerOpen } = props;
  return (
    <HamburgerLinks open={hamburgerOpen}>
      {Links.map(({ label, to }) => (
        <HamburgerLink
          key={label}
          className={to === page ? "active" : ""}
          href={"#" + to}
          onClick={() => setHamburgerOpen(false)}
        >
          {label}
        </HamburgerLink>
      ))}
    </HamburgerLinks>
  );
}
