export default function canvasBorderEnvironment(canvas) {
  return {
    update: function (particle) {
      if (particle.location.x > canvas.width || particle.location.x < 0) {
        particle.velocity.x *= -1;
      }
      if (particle.location.y > canvas.height || particle.location.y < 0) {
        particle.velocity.y *= -1;
      }
    },
  };
}
