import styled from "styled-components";
import ReactPlayer from "react-player/youtube";
import Page from "../Layout/Page";
import fluid from "../utils/fluid";
import { device } from "../utils/size";

const Wrapper = styled.div`
  width: 100%;
  /* height: 100vh; */

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: 110rem;

  display: flex;
  flex-flow: column;
`;

const PageTitle = styled.h2`
  font-size: ${fluid(3, 6)};
  font-weight: 600;
  color: white;
  margin-bottom: 5rem;
  text-transform: uppercase;

  @media ${device.laptop} {
    position: sticky;
    top: 0;
    min-height: 30vh;
    padding: ${fluid(3, 6)};
    display: flex;
    align-items: center;
  }
`;

const SpeakerTitle = styled.div`
  color: white;
  font-size: ${fluid(2, 4)};
  margin-top: ${fluid(1.5, 3)};
  font-weight: 400;
`;

const VideoContainer = styled.div`
  color: white;

  position: relative;
  padding-top: 56.25%;
`;

const VideoPlayerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const VideoTitle = styled.h3`
  font-size: ${fluid(1, 2)};
  padding: ${fluid(0.5, 1)} ${fluid(0.75, 1.5)};
  height: ${fluid(2.5, 5)};
  display: flex;
  align-items: center;
`;

const VideoFooter = styled.div`
  font-size: ${fluid(0.8, 1.6)};
  padding: ${fluid(0.5, 1)} ${fluid(0.75, 1.5)};
  height: ${fluid(2.5, 5)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  height: 100%;
`;

const Logo = styled.img`
  height: 100%;
`;

export default function PreviousClasses(props) {
  const { title, handlePageEnter, url, speakerTitle } = props;
  return (
    <Page title={title} handlePageEnter={handlePageEnter} frontOfParticle>
      <Wrapper id={title}>
        <Container>
          <PageTitle>Previous Classes</PageTitle>
          <VideoContainer>
            <VideoPlayerContainer>
              <ReactPlayer
                url={url}
                width={"100%"}
                height={"100%"}
                controls={true}
              />
            </VideoPlayerContainer>
          </VideoContainer>
          <SpeakerTitle>{speakerTitle}</SpeakerTitle>
        </Container>
      </Wrapper>
    </Page>
  );
}
