import shapeOne from "../assets/contour/shapeone.json";
import shapeTwo from "../assets/contour/shapetwo.json";
import shapeThree from "../assets/contour/shapethree.json";

const shapeDict = {
  masterclass: shapeOne,
  speakers1: shapeOne,
  speakersnext: shapeTwo,
  partner: shapeOne,
  info: shapeOne,
  news: shapeThree,
  newsTwo: shapeThree,
};

const shapes = (page, isTablet) => {
  let shape = shapeDict[page];

  if (page.includes("news") && isTablet) {
    shape = shapeOne;
  }

  return shape;
};
export default shapes;
