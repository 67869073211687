import styled from "styled-components";
import Page from "../Layout/Page";
import PageContent from "../Layout/PageContent";
import PageTitle from "../Layout/PageTitle";
import Section from "../Layout/Section";
import fluid from "../utils/fluid";

import VENUEIMAGE from "../assets/sectionImage/venue.png";
import SAMYANIMAGE from "../assets/sectionImage/samyan.png";
import ActionButton from "./ActionButton";
import MapButton from "./MapButton";

const Venue = styled.h2`
  text-transform: uppercase;
  font-weight: 600;
`;

const Room = styled.div`
  font-size: ${fluid(2, 4)};
  margin-bottom: ${fluid(1, 2)};
`;

const Place = styled.div`
  font-size: ${fluid(2, 4)};
  font-weight: 600;
  margin-bottom: ${fluid(1.6, 3.2)};

  & .description {
    text-transform: uppercase;
    font-weight: 400;
    margin-top: ${fluid(1.6, 3.2)};
  }

  & a {
    color: white;
  }
`;

export default function VenuePage(props) {
  const { title, handlePageEnter, handleSectionEnter } = props;
  return (
    <Page title={title} handlePageEnter={handlePageEnter}>
      <PageTitle>Partners</PageTitle>
      <PageContent>
        <Section
          id={title}
          page={title}
          title={"description"}
          handleSectionEnter={handleSectionEnter}
          src={VENUEIMAGE}
        >
          <Venue>
            <Place>
              <a href={"https://www.truedigitalpark.com/home"} target="_blank">
                True Digital Park
              </a>
              <div className="description">House of Digital Academy</div>
            </Place>
          </Venue>
        </Section>
        <Section
          id={title + "Samyan"}
          page={title}
          title={"Samyan"}
          handleSectionEnter={handleSectionEnter}
          src={SAMYANIMAGE}
        >
          <Venue>
            <Place>
              <p>AWAKENING SAMYAN</p>
              <div className="description" style={{ textTransform: "none" }}>
                Presented by <br />
                Time Out Bangkok in collaboration with TCEB
              </div>
            </Place>
          </Venue>
        </Section>
      </PageContent>
    </Page>
  );
}
