import styled from "styled-components";
import Page from "../Layout/Page";
import PageContent from "../Layout/PageContent";
import PageTitle from "../Layout/PageTitle";
import Section from "../Layout/Section";

import fluid from "../utils/fluid";
import MASTERCLASSIMAGE from "../assets/sectionImage/master_class.png";
import BITLOGO from "../assets/horizontal_logo.svg";

const Description = styled.div`
  font-size: ${fluid(1.8, 4)};
  font-weight: 400;
`;

const BitLogo = styled.img`
  vertical-align: middle;
  height: ${fluid(1.8, 4)};
  margin-bottom: 0.5rem;
`;
export default function MasterClassPage(props) {
  const { title, handlePageEnter, handleSectionEnter } = props;
  return (
    <Page title={title} handlePageEnter={handlePageEnter}>
      <PageTitle>
        Master
        <br />
        Class
      </PageTitle>
      <PageContent>
        <Section
          id="masterclass"
          page={title}
          title={"description"}
          handleSectionEnter={handleSectionEnter}
          src={MASTERCLASSIMAGE}
        >
          <Description>
            A series of talks related to art, design, and technology curated by{" "}
            <BitLogo src={BITLOGO} />
          </Description>
        </Section>
      </PageContent>
    </Page>
  );
}
