import styled from "styled-components";
import fluid from "../utils/fluid";
import { device } from "../utils/size";

export default styled.div`
  text-transform: uppercase;
  flex-basis: 35%;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;

  color: white;
  font-size: ${fluid(3, 6)};
  font-weight: 600;
  padding: ${fluid(3, 6)};

  @media ${device.tablet} {
    min-height: 30vh;
    height: auto;
    align-items: flex-start;
  }
`;
