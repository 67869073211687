import styled from "styled-components";
import Page from "../Layout/Page";
import PageContent from "../Layout/PageContent";
import PageTitle from "../Layout/PageTitle";
import Section from "../Layout/Section";

import ABOUTIMAGE from "../assets/sectionImage/info.png";

const Description = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.2rem;
  word-break: break-word;

  p + p {
    margin-top: 2rem;
  }
`;

const BitAcademyLink = styled.a`
  text-decoration: none;
  border-bottom: 1px solid white;
  color: white;
`;
export default function AboutPage(props) {
  const { title, handlePageEnter, handleSectionEnter } = props;
  return (
    <Page title={title} handlePageEnter={handlePageEnter}>
      <PageTitle>Info</PageTitle>
      <PageContent>
        <Section
          id={title}
          page={title}
          title={"description"}
          handleSectionEnter={handleSectionEnter}
          src={ABOUTIMAGE}
        >
          <Description>
            <p>
              Creative Technology & New Media Art Master Class is a series of
              talks related to art, design, and technology. The class offers
              participants the opportunity to hear and learn from the world’s
              best teams around the globe.
            </p>
            <p>
              The purpose of this class is to create a learning center and
              sharing space for people who are interested in developing works in
              the Creative Technology field. Our ultimate goal is to push and
              expand the boundaries of the Creative Technology and New Media Art
              industry in Thailand.
            </p>
            <p>
              For more information
              <br />
              <BitAcademyLink
                target="_blank"
                href="https://bitacademy2020.page.link/facebook_page"
              >
                bit.academy2020
              </BitAcademyLink>
              <br />
              +668 3832 6600
            </p>
          </Description>
        </Section>
      </PageContent>
    </Page>
  );
}
