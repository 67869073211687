export default [
  {
    label: "Master Class",
    to: "masterclass",
  },
  {
    label: "Speakers",
    to: "speakers",
  },
  {
    label: "News",
    to: "news",
  },
  {
    label: "Previous Classes",
    to: "previousclasses",
  },
  {
    label: "Partners",
    to: "partners",
  },
  {
    label: "Info",
    to: "info",
  },
];
