import { scaleLinear } from "d3-scale";
import whiteAlpha from "../utils/whiteAlpha";
import { context } from "./index";
import particleconfig from "./config";
import gradient from "../utils/gradient";

const lifespanScale = scaleLinear()
  .domain([0, particleconfig.lifespan])
  .range([0, 1]);
export default class Particle {
  constructor(location, base, translate, velocity, size, color, gradientScale) {
    this.location = location;
    this.base = base;
    this.translate = translate;
    this.velocity = velocity;
    this.size = size;
    this.color = color;
    this.gradientScale = gradientScale;
    this.atBase = false;
    this.lifespan = particleconfig.lifespan;
    this.environments = [];
  }

  draw() {
    context.beginPath();
    context.globalAlpha = 1;
    context.arc(
      this.location.x,
      this.location.y,
      this.size,
      0,
      Math.PI * 2,
      false
    );
    context.fillStyle = this.color;
    context.fill();
  }

  addEnvironments(environment) {
    this.environments.push(environment);
  }

  removeEnvironments(environment) {
    const index = this.environments.indexOf(environment);

    if (index > -1) {
      this.environments.splice(index, 1);
      console.log(this.environments.length);
    }
  }

  updateColor() {
    if (this.atBase) {
      const opacity = lifespanScale(this.lifespan);
      this.color = whiteAlpha(opacity);
    } else {
      this.color = gradient(this.gradientScale(this.location.x));
    }
  }
  update() {
    this.updateColor();
    this.environments.forEach((environment) => environment.update(this));

    this.location.add(this.velocity);

    this.draw();

    if (this.atBase) {
      this.lifespan -= 1;
    }
  }
}
