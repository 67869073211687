import { useEffect, useRef } from "react";
import useInView from "react-cool-inview";
import styled from "styled-components";
import fluid from "../utils/fluid";
import imageId from "../utils/imageId";
import { device } from "../utils/size";

const Container = styled.div`
  min-height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  color: white;

  @media ${device.tablet} {
    flex-flow: column nowrap;
  }
`;

const ImageContainer = styled.div`
  width: 46.15%;
  min-width: 46.15%;
  max-width: 46.15%;
  /* flex: 1 0 46.15%; */
  position: relative;

  &::after {
    visibility: ${({ backdrop }) => (backdrop ? "visible" : "hidden")};
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    background: linear-gradient(
      to top right,
      rgb(82, 58, 223) 0%,
      rgb(143, 243, 196) 100%
    );
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 50%;
  }

  @media ${device.tablet} {
    width: 100%;
    max-width: ${fluid(27.5, 55)};
    min-width: auto;
    margin-bottom: 5vh;
    margin-right: 0;
    flex: none;
  }
`;

const Image = styled.img`
  border-radius: 50%;
  width: 100%;
  ${({ src }) => (src ? "" : "padding-top: 100%;")}
`;

const Content = styled.div`
  padding: ${fluid(3, 6)};
  word-break: break-word;
  flex: 1;
`;

export default function Section(props) {
  const { id, page, title, handleSectionEnter, src, children } = props;
  const { ref } = useInView({
    threshold: 0,
    onEnter: () => handleSectionEnter(title),
  });

  return (
    <Container id={id} ref={ref}>
      <ImageContainer backdrop={!!src}>
        <Image id={imageId(page, title)} src={src} />
      </ImageContainer>
      <Content>{children}</Content>
    </Container>
  );
}
