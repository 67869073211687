export default function fluid(
  minsize,
  maxsize,
  minwidth = 320,
  maxwidth = 1920
) {
  return `calc(${minsize}rem + (${
    (maxsize - minsize) * 10
  }) * ((100vw - ${minwidth}px) / (${maxwidth - minwidth})))`;
}
