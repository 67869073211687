import styled from "styled-components";

import { HamburgerIcon } from "./Hamburger";
import BITLOGO from "../assets/horizontal_logo.svg";
import { isLaptop, isTablet } from "../utils/size";
import Links from "../utils/Links";
import fluid from "../utils/fluid";

const NavigationBarContainer = styled.nav`
  width: 100vw;
  padding: ${fluid(1, 2)} ${fluid(2, 4)};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  backdrop-filter: blur(10px);
`;

const BitLogo = styled.a`
  margin-right: auto;
  & img {
    height: 2.8rem;
  }
`;

export const NavigationLink = styled.div``;
export const Link = styled.a`
  text-decoration: none;
  color: white;
  font-size: 1.8rem;
  font-weight: 400;
  text-transform: uppercase;

  & + & {
    margin-left: 4.8rem;
  }

  &:hover,
  &.active {
    font-weight: 600;
  }
`;

export default function NavigationBar(props) {
  const { page, hamburgerOpen, setHamburgerOpen } = props;
  return (
    <NavigationBarContainer id="nav">
      <BitLogo href={"#hero"}>
        <img src={BITLOGO} />
      </BitLogo>
      {!isLaptop() ? (
        <NavigationLink>
          {Links.map(({ label, to }) => (
            <Link
              key={label}
              className={page.includes(to) ? "active" : ""}
              href={"#" + to}
            >
              {label}
            </Link>
          ))}
        </NavigationLink>
      ) : (
        <HamburgerIcon
          hamburgerOpen={hamburgerOpen}
          setHamburgerOpen={setHamburgerOpen}
        />
      )}
    </NavigationBarContainer>
  );
}
