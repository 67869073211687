import styled from "styled-components";
import useInView from "react-cool-inview";
import fluid from "../../utils/fluid";

import { device, isTablet } from "../../utils/size";
import imageId from "../../utils/imageId";

const ArchiveContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Archive = styled.div`
  width: 100%;
  max-width: 110rem;
`;

const Title = styled.h2`
  font-size: ${fluid(3, 6)};
  font-weight: 600;
  color: white;
  margin-bottom: 5rem;
  text-transform: uppercase;

  @media ${device.laptop} {
    position: sticky;
    top: 0;
    min-height: 30vh;
    padding: ${fluid(3, 6)};
    display: flex;
    align-items: center;
  }
`;

const ArchiveList = styled.ul`
  list-style: none;

  display: flex;
  justify-content: space-between;

  @media ${device.laptop} {
    flex-flow: column nowrap;
    align-items: center;
  }
`;

const ArchiveListItem = styled.li`
  flex: 0 0 30%;

  @media ${device.laptop} {
    min-height: 100vh;
    width: 80%;
    max-width: 50rem;
  }
`;

const ArchiveImage = styled.img`
  width: 100%;
  margin-bottom: 4rem;
`;

export default function NewsPage(props) {
  const { title, handlePageEnter, handleSectionEnter, news } = props;
  const { ref } = useInView({
    threshold: 0,
    onEnter: () => {
      handlePageEnter(title);
      if (!isTablet()) {
        handleSectionEnter("desktop");
      }
    },
  });

  return (
    <ArchiveContainer>
      <Archive id={title} ref={ref}>
        <Title>News</Title>
        <ArchiveList id={imageId(title, "desktop")}>
          {news.map(({ src, Content }, i) => {
            return (
              <ArchiveInview
                key={i}
                src={src}
                page={title}
                section={i}
                handleSectionEnter={handleSectionEnter}
              >
                {Content}
              </ArchiveInview>
            );
          })}
        </ArchiveList>
      </Archive>
    </ArchiveContainer>
  );
}

function ArchiveInview(props) {
  const { src, page, section, handleSectionEnter, children } = props;
  const { ref } = useInView({
    threshold: 0,
    onEnter: () => {
      if (isTablet()) {
        handleSectionEnter(section);
      }
    },
  });
  return (
    <ArchiveListItem key={section}>
      <ArchiveImage src={src} ref={ref} id={imageId(page, section)} />
      {children}
    </ArchiveListItem>
  );
}
