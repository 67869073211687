import React, { useEffect, useRef } from "react";
import createParticleEffect from "../particles";
import shapes from "../particles/shapes";

import imageId from "../utils/imageId";
import { isTablet } from "../utils/size";

let particleEffect;

let wactchedImg;
export default function ParticleEffect(props) {
  const { className, page, section } = props;

  const canvasRef = useRef(null);
  useEffect(() => {
    if (!canvasRef) return;
    particleEffect = createParticleEffect(canvasRef.current);
    particleEffect.resize();
    particleEffect.init();
    particleEffect.animate();

    window.addEventListener("resize", () => {
      let newRadius = 0.3 * window.innerWidth;
      if (wactchedImg) {
        newRadius = wactchedImg.getBoundingClientRect().width;
      }
      particleEffect.resize(newRadius);
    });

    window.addEventListener("scroll", () => {
      if (!wactchedImg) return particleEffect.centerTranslate();

      const { top, left } = wactchedImg.getBoundingClientRect();
      particleEffect.setTranslate(left, top);
    });
  }, [canvasRef]);

  useEffect(() => {
    if (page === "hero" || page === "previousclasses") {
      particleEffect.removeBaseEnvironment();
    } else {
      particleEffect.addBaseEnvironment();
      const shape = shapes(page, isTablet());
      particleEffect.setShape(shape);
    }
  }, [page]);

  useEffect(() => {
    wactchedImg = document.querySelector("#" + imageId(page, section));

    if (wactchedImg) {
      const { width: imageRadius } = wactchedImg.getBoundingClientRect();
      particleEffect.setRadius(imageRadius);
    }
  }, [page, section]);

  return <canvas ref={canvasRef} className={className} />;
}
