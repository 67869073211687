import styled from "styled-components";

import fluid from "../../utils/fluid";

import ARCHIVEONE from "../../assets/archive/teamlab_1.png";
import ARCHIVETWO from "../../assets/archive/fd_1.png";
import ARCHIVETHREE from "../../assets/archive/onformative_1.png";
import ARCHIVEFOUR from "../../assets/archive/art_com_1.png";
import ARCHIVEFIVE from "../../assets/archive/pangenerator.png";
import ARCHIVESIX from "../../assets/archive/kimchi_and_chips.png";

const NewsContent = styled.div`
  font-size: ${fluid(1.6, 2.2)};
  line-height: ${fluid(1.8, 3.6)};
  color: white;
  font-weight: 400;

  & b {
    font-size: ${fluid(1.9, 2.5)};
    font-weight: 600;
  }
`;

export const newsOne = [
  {
    src: ARCHIVEONE,
    Content: (
      <NewsContent>
        <b>December 15, 2020</b>
        <p>
          Our first class featured teamLab as our guest speaker. The concepts
          and ideas behind their works were shared during the session.
        </p>
      </NewsContent>
    ),
  },
  {
    src: ARCHIVETWO,
    Content: (
      <NewsContent>
        <b>January 26, 2021</b>
        <p>
          Our second class: Future Formula, Design through experimentation,
          featured Will Denning, technical director of FutureDeluxe, London.
        </p>
      </NewsContent>
    ),
  },
  {
    src: ARCHIVETHREE,
    Content: (
      <NewsContent>
        <b>Feb 24, 2021</b>
        <p>
          Cedric Kiefer, co-founder / creative lead from onformative shared
          their thinking & creating processes under the topic ‘Searching for new
          ways of creative expression’.
        </p>
      </NewsContent>
    ),
  },
];

export const newsTwo = [
  {
    src: ARCHIVEFOUR,
    Content: (
      <NewsContent>
        <b>April 29, 2021</b>
        <p>
          Jussi Ängeslevä - vice creative director of ART+COM studios, shared
          the stories behind their projects and their research on digitization
          of museums as a means of preserving museums in the face of pandemic.
        </p>
      </NewsContent>
    ),
  },
  {
    src: ARCHIVEFIVE,
    Content: (
      <NewsContent>
        <b>July 22, 2021</b>
        <p>
          panGenerator shared their insights on how they create
          audiences-engaging, dynamic, and tangible experiences by blending
          ephemeral digital realm with physical world.
        </p>
      </NewsContent>
    ),
  },
  {
    src: ARCHIVESIX,
    Content: (
      <NewsContent>
        <p>
          <b> Oct 20, 2021</b>
          <p>
            Our sixth class featured Elliot Woods, co-founder of Kimchi and
            Chips. Concepts behind their art and how they created it were
            discussed under the theme 'Images that change the way we see'.
          </p>
        </p>
      </NewsContent>
    ),
  },
];
